import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '@app/modules/auth/page/login/login.component';
import { socialHandles } from '@constants/socialHandles';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public stayConnected: {
    key: number;
    title: string;
    link: string;
    imagePath: string;
  }[] = socialHandles;
  public tiepleLinks1: { text: string; url: string; event: boolean }[] = [
    { text: 'Application Form', url: 'selection-application', event: false },
    { text: 'Registration', url: '', event: true },
    { text: 'Schedule Counseling', url: 'booking-counselling', event: false },
    { text: 'Start Your Journey', url: 'user-journey', event: false },
    //{ text: 'Borderless Agile', url: 'course-detail/TPL_100003' },
    // { text: 'Full Stack Developer Corporate Program', url: 'course-detail/TPL_100001' }
  ];
  public tiepleLinks2: { text: string; url: string }[] = [
    { text: 'Terms and Conditions', url: 'privacy-notice' },
    { text: 'Privacy Policy', url: 'privacy-policy' },
    { text: 'Security & Fraud', url: 'security-policy' },
    { text: 'Cookie Policy', url: 'cookie-policy' },
    //{ text: 'Send Feedback', url: ''}
  ];

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  openLoginDialog() {
    this.dialog.open(LoginComponent, {
      maxHeight: '560px',
      maxWidth: '800px',
      height: '100%',
      width: '100%',
      panelClass: 'login-modal',
    });
  }
}
