export const sidebarMenuItems: { key: number, title: string, link: string, imagePath: string }[] = [
  {
    key: 1,
    title: 'Why Tiple',
    link: 'https://www.fb.com',
    imagePath: "../../../../assets/icons/QuestionMarkIcon.png"
  },
  {
    key: 2,
    title: 'Courses',
    link: 'course-detail',
    imagePath: "../../../../assets/icons/BookmarkIcon.png"
  },
  {
    key: 3,
    title: 'Mentorship',
    link: 'https://www.youtube.com',
    imagePath: "../../../../assets/icons/MentorshipIcon.png"
  },
  {
    key: 4,
    title: 'For Companies',
    link: 'https://www.linkedin.com',
    imagePath: "../../../../assets/icons/CompanyIcon.png"
  },
  {
    key: 5,
    title: 'For Students',
    link: 'student-profile',
    imagePath: "../../../../assets/icons/StudentIcon.png"
  }
];
