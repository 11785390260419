import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { ILearningPath } from '@app/model/learning-path';
import { eColName } from '@app/model/collections';
import { collection } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { collectionData } from 'rxfire/firestore';

@Injectable({
  providedIn: 'root'
})
export class LearningPathService {

  private learningPath: ILearningPath[] = [];

  constructor(private firestore: Firestore) { }

  getLearningPath(): Observable<ILearningPath[]> {
    const learning = collection(this.firestore, eColName.LEARNINGPATH);
    return collectionData(learning, { idField: "id" }) as Observable<ILearningPath[]>;

  }
}
