const pkg = require('../package.json');

export const environment = {
  appName: 'Tieple',
  appVersion: `${pkg.version}-Local`,
  firebase: {
    apiKey: 'AIzaSyDlfMP016YXK-lE2kLIS9wabM9RS2QmcX0',
    authDomain: 'tieple-dev.firebaseapp.com',
    databaseURL: 'https://tieple-dev.firebaseio.com',
    projectId: 'tieple-dev',
    storageBucket: 'tieple-dev.appspot.com',
    messagingSenderId: '1001605401012',
    appId: '1:1001605401012:web:8bcabcbe27e9d0332d4ae1',
    measurementId: 'G-YR6F97RNS6',
  },
  isCloudDeployment: false,
  isDevelopEnv: true,
  isProductionBuild: false,
  isTestEnv: false,
  userWelcomeEmailTrigger:
    'https://us-central1-tieple-dev.cloudfunctions.net/updateUserInfo',
  counsellingTrigger:
    'https://us-central1-tieple-dev.cloudfunctions.net/counsellingNotification',
};
