import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  defaultImg: string = "assets/icons/no-image-100.png";
  constructor(private http: HttpClient) { }

  transform(url: string): Observable<string> {
    this.http.get(url).subscribe({
      next: () => {
        return of(url);
      }
    });
    return of(this.defaultImg);
  }

}
