<div [ngClass]="sidebarOpen ? 'root-container nav-open' : 'root-container'">
  <div *ngIf="sidebarOpen" class="sidebar-menu">
    <h5>Main Menu</h5>
    <div class="header-underline"></div>
    <ul>
      <li>
        <a [routerLink]="['about']" class="light">
          <img src="../assets/icons/QuestionMarkIcon.png" />
          <span>Why Tieple?</span>
        </a>
      </li>
      <li
        (click)="expandMenu('students')"
        [ngClass]="currentMenu === 'students' ? 'active' : ''"
      >
        <a>
          <img src="../assets/icons/StudentIcon.png" />
          <span>For Students</span>
          <img class="arrowicon" src="../assets/icons/rightarrow.png" />
        </a>
        <ul *ngIf="currentMenu === 'students'" class="submenu">
          <li
            (click)="expandSubMenu('courses')"
            [ngClass]="currentSubMenu === 'courses' ? 'active' : ''"
          >
            <a class="light regular">
              <img src="../assets/icons/link-icon-new.png" class="custom" />
              <span>Courses</span>
              <img class="arrowicon" src="../assets/icons/rightarrow.png" />
            </a>
            <ul *ngIf="currentSubMenu === 'courses'" class="submenu">
              <li *ngFor="let courses of courseService.courses">
                <a
                  (click)="
                    gotoSelectedRoute('course-detail/' + courses.courseId + '')
                  "
                  class="light regular"
                >
                  <img src="../assets/icons/ArrowDown.png" />
                  <span>{{ courses.courseTitle }}</span>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a (click)="gotoSelectedRoute('')" class="light regular">
              <img src="../assets/icons/RecieptIcon.png" class="custom" />
              <span>Application Form</span>
            </a>
          </li>
          <li>
            <a (click)="gotoSelectedRoute('')" class="light regular">
              <img src="../assets/icons/Counseling.png" class="custom" />
              <span>Book A Counselling</span>
            </a>
          </li>
          <!-- <li>
            <a (click)="gotoSelectedRoute('')" class="light regular">
              <img src="../assets/icons/question.png" class="custom">
              <span>Ask Your Question</span>
            </a>
          </li> -->
          <li>
            <a
              (click)="gotoSelectedRoute('selection-process')"
              class="light regular"
            >
              <img src="../assets/icons/question.png" class="custom" />
              <span>Selection Process</span>
            </a>
          </li>
          <li>
            <a (click)="gotoSelectedRoute('')" class="light regular">
              <img src="../assets/icons/StarIcon.png" class="custom" />
              <span
                >Scholarship
                <span style="font-size: 10px; font-style: italic"
                  >(Coming soon)</span
                ></span
              >
            </a>
          </li>
          <li>
            <a (click)="gotoSelectedRoute('faqs/Tieple')" class="light regular">
              <img src="../assets/icons/BoxIcon.png" class="custom" />
              <span>FAQs</span>
            </a>
          </li>
        </ul>
      </li>
      <!-- <li>
        <a (click)="gotoSelectedRoute('')" class="light">
          <img src="../assets/icons/CompanyIcon.png">
          <span>For Companies</span>
        </a>
      </li> -->
      <!-- <li (click)="expandMenu('entrepreneurs')" [ngClass]="currentMenu === 'entrepreneurs' ? 'active' : ''">
        <a class="light">
          <img src="../assets/icons/QuestionMarkIcon.png">
          <span>For Entrepreneurs</span>
          <img class="arrowicon" src="../assets/icons/rightarrow.png">
        </a>
        <ul *ngIf="currentMenu === 'entrepreneurs'" class="submenu">
          <li>
            <a (click)="gotoSelectedRoute('')" class="light regular">
              <img src="../assets/icons/LampIcon.png" class="custom">
              <span>Tieple Independent Development Centre</span>
            </a>
          </li>
          <li>
            <a (click)="gotoSelectedRoute('faqs/Tieple')" class="light regular">
              <img src="../assets/icons/BoxIcon.png" class="custom">
              <span>FAQs</span>
            </a>
          </li>
        </ul>
      </li> -->
      <li
        (click)="expandMenu('mentors')"
        [ngClass]="currentMenu === 'mentors' ? 'active' : ''"
      >
        <a class="light">
          <img src="../assets/icons/MentorshipIcon.png" />
          <span>For Mentors</span>
          <img class="arrowicon" src="../assets/icons/rightarrow.png" />
        </a>
        <ul *ngIf="currentMenu === 'mentors'" class="submenu">
          <li>
            <a (click)="gotoSelectedRoute('')" class="light regular">
              <img src="../assets/icons/RecieptIcon.png" class="custom" />
              <span>Apply to join as mentor</span>
            </a>
          </li>
          <li>
            <a (click)="gotoSelectedRoute('faqs/Tieple')" class="light regular">
              <img src="../assets/icons/BoxIcon.png" class="custom" />
              <span>FAQs</span>
            </a>
          </li>
        </ul>
      </li>
      <!-- <li (click)="expandMenu('colleges')" [ngClass]="currentMenu === 'colleges' ? 'active' : ''">
        <a class="light">
          <img src="../assets/icons/QuestionMarkIcon.png">
          <span>For Colleges</span>
          <img class="arrowicon" src="../assets/icons/rightarrow.png">
        </a>
        <ul *ngIf="currentMenu === 'colleges'" class="submenu">
          <li>
            <a (click)="gotoSelectedRoute('')" class="light regular">
              <img src="../assets/icons/RecieptIcon.png" class="custom">
              <span>Apply to invite</span>
            </a>
          </li>
          <li>
            <a (click)="gotoSelectedRoute('faqs/Tieple')" class="light regular">
              <img src="../assets/icons/BoxIcon.png" class="custom">
              <span>FAQs</span>
            </a>
          </li>
        </ul>
      </li> -->
      <li>
        <a (click)="gotoSelectedRoute('news/JOB')" class="light">
          <img src="../assets/icons/QuestionMarkIcon.png" />
          <span>QuickEye</span>
        </a>
      </li>
      <li>
        <a (click)="gotoSelectedRoute('century-league')" class="light">
          <img src="../assets/icons/century-league.png" />
          <span>Century League</span>
        </a>
      </li>
    </ul>
  </div>
  <router-outlet></router-outlet>
</div>
