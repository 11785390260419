export enum eColName {
  STUDENTS = 'STUDENTS',
  COURSES = 'COURSES',
  CONTENT = 'CONTENT',
  TEACHINGASSISTANT = 'TEACHINGASSISTANT',
  LEARNINGPATH = 'LEARNINGPATH',
  JOBROLES = 'JOBROLES',
  BATCHES = 'BATCHES',
  STUDENTAPPLICATION = 'STUDENTAPPLICATION',
  STUDENTQUIZ = 'STUDENTQUIZ',
  COUNSELLING_SLOTS = 'COUNSELLING_SLOTS',
  BOOKED_SLOTS = 'BOOKED_SLOTS',
  JOURNEY = 'JOURNEY',
  COUNSELLING_CONFIG = 'COUNSELLING_CONFIG',
  BOOKED_MENTOR_SLOTS = 'BOOKED_MENTOR_SLOTS',
  NEWS = 'NEWS',
  MENTOR_CATEGORIES = 'MENTOR_CATEGORIES',
}
