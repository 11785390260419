<div class="dashboard__experts-say">
    <h5 class="dashboard__experts-say-header lg">{{title}}</h5>
    <div class="dashboard__experts-say-seperator"></div>
    <div class="dashboard__experts-say-videos">
        <div *ngFor="let video of videoContent" class="dashboard__experts-say-videos-item">
            <iframe class="dashboard__experts-say-videos-player" [src]="video" frameborder="0" allowfullscreen></iframe>
            <!-- <youtube-player [height]="220" [width]="305" class="dashboard__experts-say-videos-player" [videoId]="video" (ready)="savePlayer($event)"
                [playerVars]="playerVars">
            </youtube-player> -->
        </div>

    </div>
</div>