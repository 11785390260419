import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-code-dialog',
  templateUrl: './student-code-dialog.component.html',
  styleUrls: ['./student-code-dialog.component.scss'],
})
export class StudentCodeDialogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
