import { Component, OnInit } from '@angular/core';
import { VNxtRouteService } from "@vnxt/angular-auth";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SideNavService } from '@app/services/side-nav.service';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss']
})

export class ContentLayoutComponent implements OnInit {

  sidebarOpen = false;
  constructor(
    private routerService: VNxtRouteService,
    private activatedRoute: ActivatedRoute,
    private sidenavService: SideNavService,
    private router: Router
  ) {
    this.routerService.makeBreadcrumTreeRecursive(activatedRoute);
  }

  ngOnInit(): void {
    this.router.events.subscribe(ev => {
      // console.log('router ev', ev);
      if (!(ev instanceof NavigationEnd)) {
        return;
      }
      document.getElementsByClassName('content-scroll-area')[0].scrollTo(0,0)
    });
  }

  ngAfterContentChecked() {
    this.sidebarOpen = this.sidenavService.sidenavOpen;
  }
}
