import { Component, Input, OnInit } from '@angular/core';
import { ICourse } from '@app/model/Course';
import { LearningPathService } from '@app/services/learning-path.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-selection-procedure',
  templateUrl: './selection-process.component.html',
  styleUrls: ['./selection-process.component.scss']
})
export class SelectionProcessComponent implements OnInit {

  selectionProcessJourney: any = [];
  currentProcess = 0;
  @Input() courseDetail: Observable<ICourse> = new Observable<ICourse>();
  @Input() subtitleContent;

  constructor(private lp: LearningPathService) { }

  ngOnInit(): void {
    this.selectionProcessJourney = this.courseDetail['selectionProcess'];
    console.log('Selection Process', this.selectionProcessJourney);
  }

  switchPath(pathIndex) {
    this.currentProcess = pathIndex;
  }

}
