<div class="dashboard__teachers">
  <h5 class="dashboard__teachers-title lg">{{ title }}</h5>
  <p class="dashboard__teachers-subtitle p3">
    A growing team of enthusiatic, experienced technology practitioners and IT
    industry experts with the vision of guiding students/candidates in their
    professional journey. Mentors will be in continuous connect with the
    students.
  </p>
  <div class="dashboard__teachers-cards">
    <div
      class="dashboard__teachers-card"
      *ngFor="let teacher of teacherDetails"
    >
      <img [src]="teacher.profilePic" class="dashboard__teachers-card-avatar" />
      <h3 class="dashboard__teachers-card-name">{{ teacher.name }}</h3>
      <p
        *ngIf="teacher.previousOrg.length > 0"
        class="dashboard__teachers-card-description p5-md"
      >
        Work Experience in
        <ng-container *ngFor="let item of teacher.previousOrg; let i = index">
          <span>{{ item }}</span>
          <span *ngIf="i !== teacher.previousOrg.length - 1">, </span>
          <span *ngIf="i === teacher.previousOrg.length - 1">.</span>
        </ng-container>
      </p>
      <div class="dashboard__teachers-card-seperator"></div>
      <div class="dashboard__teachers-card-moreinfo">
        <img src="../../../../assets/icons/play-btn.png" />
        <span>Play for more</span>
        <!-- Tooltip -->
        <div class="dashboard__teachers-card-tooltip">
          <div class="dashboard__teachers-card-tooltip-topsection">
            <div class="dashboard__teachers-card-tooltip-leftsection">
              <img [src]="teacher.profilePic" />
              <img
                src="../../../../assets/icons/play-btn-lg.png"
                class="play-btn"
              />
            </div>
            <div class="dashboard__teachers-card-tooltip-rightsection">
              <h5>{{ teacher.name }}</h5>
              <div>
                Worked in
                <ng-container
                  *ngFor="let item of teacher.previousOrg; let i = index"
                >
                  {{ item.trim() }}
                  <ng-container *ngIf="i !== teacher.previousOrg.length - 1"
                    >,
                  </ng-container>
                  <ng-container *ngIf="i === teacher.previousOrg.length - 1"
                    >.</ng-container
                  >
                </ng-container>
              </div>
              <div>{{ teacher.experienceYears }}+ years of IT Experience</div>
            </div>
          </div>
          <div class="dashboard__teachers-card-tooltip-bottomsection">
            <ul>
              <li>
                Developed data insight software product for TCS's client and now
                it's know as Byzzer
              </li>
              <li>
                Led the team of UK Railway system management product in ATOS
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <vnxt-button
    class="dashboard__teachers-btn"
    title="Goto Mentors Home"
    variant="btn-primary"
    routerLink="mentors-home"
  >
  </vnxt-button>
</div>
