<div [ngClass]="sidebarOpen ? 'app-navbar nav-open' : 'app-navbar'">
  <div class="app-navbar__mobile-sidebar-menu" (click)="menuAction()">
    <img
      alt="menu"
      class="app-navbar__mobile-sidebar-menu-img"
      src="../../../../assets/icons/SidebarMenuIconSmall.png"
    />
  </div>
  <div class="app-navbar__logo" [routerLink]="['']">
    <img
      alt="logo"
      class="app-navbar__logo-img"
      src="../../../../assets/images/tieple_logo.png"
    />
    <div class="app-navbar__logo-name">tieple</div>
  </div>
  <div class="app-navbar__center-menu">
    <span [routerLink]="['about']" class="app-navbar__menu">Why Tieple?</span>
    <span class="app-navbar__menu" [matMenuTriggerFor]="courses"
      >For Students</span
    >
    <!-- Menu for Students -->
    <mat-menu #courses="matMenu" class="for-students">
      <span class="arrow"></span>
      <div class="top-content">
        <div class="left-content">
          <div class="courses-title-wrap">
            <img
              class="courses-icon"
              src="../../../../assets/icons/link-icon-new.png"
            />
            <span class="courses-title h3">Courses</span>
          </div>
          <button
            mat-menu-item
            class="menu-item"
            routerLink="course-detail/{{ courses.courseId }}"
            *ngFor="let courses of courseService.courses"
          >
            <img src="../../../../assets/icons/ArrowDown.png" />
            <span>{{ courses.courseTitle }}</span>
          </button>
        </div>
        <div class="right-content">
          <button
            mat-menu-item
            class="menu-item"
            (click)="loadApplicationForm()"
          >
            <img src="../../../../assets/icons/RecieptIcon.png" />
            <span>Application Form</span>
          </button>
          <button
            mat-menu-item
            class="menu-item"
            routerLink="booking-counselling"
          >
            <img src="../../../../assets/icons/Counseling.png" />
            <span>Book A Counselling</span>
          </button>
          <button
            mat-menu-item
            class="menu-item"
            routerLink="selection-process"
          >
            <img src="../../../../assets/icons/selection-process.gif" />
            <span>Selection Process</span>
          </button>
          <button mat-menu-item class="menu-item">
            <img src="../../../../assets/icons/StarIcon.png" />
            <span>Scholarship</span>
            <span class="pink-text" style="font-size: 10px; font-style: italic"
              >(Coming soon)</span
            >
          </button>
          <button mat-menu-item class="menu-item" routerLink="faqs/Tieple">
            <img src="../../../../assets/icons/BoxIcon.png" />
            <span>FAQs</span>
          </button>
        </div>
      </div>
      <hr />
      <div class="bottom-content">
        <h5>For Tieple Students</h5>
        <div class="student-actions">
          <button mat-menu-item class="menu-item" (click)="showStudentDialog()">
            <img src="../../../../assets/icons/portal.png" />
            <span>Students Portal</span>
          </button>
          <button mat-menu-item class="menu-item" (click)="showStudentDialog()">
            <img src="../../../../assets/icons/StudentHome.png" />
            <span>My Profile</span>
          </button>
        </div>
      </div>
    </mat-menu>

    <!-- <span class="app-navbar__menu">For Companies</span>
    <span class="app-navbar__menu" [matMenuTriggerFor]="entrepreneursMenu"
      >For Entrepreneurs</span
    > -->
    <!-- Menu for Entrepreneurs -->
    <mat-menu #entrepreneursMenu="matMenu" class="for-entrepreneurs">
      <div class="left-content">
        <button mat-menu-item class="menu-item">
          <img src="../../../../assets/icons/LampIcon.png" />
          <span>Tieple Independent Development Centre</span>
        </button>
        <button mat-menu-item class="menu-item" routerLink="faqs">
          <img src="../../../../assets/icons/BoxIcon.png" />
          <span>FAQs</span>
        </button>
      </div>
    </mat-menu>

    <span class="app-navbar__menu" [matMenuTriggerFor]="mentorsMenu"
      >Mentors</span
    >
    <!-- Menu for Mentors -->
    <mat-menu #mentorsMenu="matMenu" class="for-mentors">
      <div class="left-content">
        <button mat-menu-item class="menu-item" routerLink="mentors-home">
          <img src="../../../../assets/icons/RecieptIcon.png" />
          <span>Apply to join as mentor</span>
        </button>
        <button mat-menu-item class="menu-item" routerLink="faqs">
          <img src="../../../../assets/icons/BoxIcon.png" />
          <span>FAQs</span>
        </button>
      </div>
    </mat-menu>

    <!-- <span class="app-navbar__menu" [matMenuTriggerFor]="collegesMenu"
      >For Colleges</span
    > -->
    <!-- Menu for Colleges -->
    <mat-menu #collegesMenu="matMenu" class="for-colleges">
      <div class="left-content">
        <button mat-menu-item class="menu-item">
          <img src="../../../../assets/icons/RecieptIcon.png" />
          <span>Apply to invite</span>
        </button>
        <button mat-menu-item class="menu-item" routerLink="faqs">
          <img src="../../../../assets/icons/BoxIcon.png" />
          <span>FAQs</span>
        </button>
      </div>
    </mat-menu>

    <span
      class="app-navbar__menu"
      [routerLink]="['news/JOB']"
      matTooltip="One stop destination for short tech news curated in house"
    >
      QuickEye
    </span>

    <span class="app-navbar__menu" [routerLink]="['century-league']">
      Century League
    </span>
  </div>

  <div class="app-navbar__right-menu">
    <span
      *ngIf="this.tokenService.isTokenExpired()"
      (click)="openLoginDialog()"
      class="app-navbar__menu"
      >Student Login</span
    >
    <div *ngIf="!this.tokenService.isTokenExpired()">
      <button
        mat-icon-button
        [matMenuTriggerFor]="profile"
        class="profile-link"
      >
        <strong>{{ this.tokenService.getUser() }}</strong>
        <mat-icon>expand_more</mat-icon>
      </button>
      <mat-menu #profile="matMenu">
        <mat-card class="profile-card">
          <mat-card-content class="profile-menu">
            <div mat-card-avatar class="profile-image"></div>
            <mat-card-title>
              {{ this.tokenService.getUser() }}
              <!-- <mat-icon aria-hidden="false" aria-label="edit" class="grey-icon edit-profile">edit</mat-icon> -->
            </mat-card-title>
            <mat-card-subtitle>{{
              this.tokenService.getEmailId()
            }}</mat-card-subtitle>
          </mat-card-content>
          <div class="profile-container">
            <mat-card-content>
              <button mat-menu-item class="menu-item">
                <mat-icon aria-hidden="false" aria-label="person_outline"
                  >person_outline</mat-icon
                >
                Profile
              </button>
              <button
                mat-menu-item
                class="menu-item"
                routerLink="selection-application"
              >
                <mat-icon aria-hidden="false" aria-label="assignment"
                  >assignment</mat-icon
                >
                My Application
              </button>
              <button mat-menu-item class="menu-item" (click)="logoutUser()">
                <mat-icon
                  aria-hidden="false"
                  aria-label="settings_backup_restore"
                >
                  settings_backup_restore</mat-icon
                >
                Logout
              </button>
            </mat-card-content>
          </div>
        </mat-card>
      </mat-menu>
      <div class="app-navbar__mobile-right-menu">
        <img
          alt="menu"
          class="app-navbar__mobile-right-menu-img"
          src="../../../../assets/icons/LogoutIcon.png"
        />
      </div>
    </div>
  </div>
</div>
