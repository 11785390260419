import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from './component/spinner/spinner.component';
import { AngularSupportModule } from '@vnxt/angular-support';
import { ISupportEnvironmentOptions } from '@vnxt/angular-support/lib/interface/common';
import { environment } from '../../../environments/environment';
import { IAuthEnvironmentOptions, VNxtAuthModule } from '@vnxt/angular-auth';
import { LoaderComponent } from './component/loader/loader.component';
import { ImagePipe } from './pipe/image.pipe';
import { ExpertsSayComponent } from './component/experts-say/experts-say.component';
import { TiepleMentorCommunityComponent } from './component/tieple-mentor-community/tieple-mentor-community.component';
import { TopTrendsComponent } from './component/top-trends/top-trends.component';
import { SelectionProcessComponent } from './component/selection-process/selection-process.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';

@NgModule({
  declarations: [
    SpinnerComponent,
    LoaderComponent,
    ImagePipe,
    ExpertsSayComponent,
    TiepleMentorCommunityComponent,
    TopTrendsComponent,
    SelectionProcessComponent,
    DragAndDropDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatToolbarModule,
    AngularSupportModule.initializeAppModule(
      environment as ISupportEnvironmentOptions
    ),
    VNxtAuthModule.initializeAppModule(environment as IAuthEnvironmentOptions),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SpinnerComponent,
    LoaderComponent,
    AngularSupportModule,
    VNxtAuthModule,
    ImagePipe,
    ExpertsSayComponent,
    TiepleMentorCommunityComponent,
    TopTrendsComponent,
    SelectionProcessComponent,
    MatToolbarModule,
  ],
})
export class SharedModule {}
