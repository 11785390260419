<div class="dashboard__top-trends">
  <h2 class="dashboard__top-trends-header">Top Trends</h2>
  <div class="dashboard__top-trends-section">
    <h3 class="dashboard__top-trends-section-title">TOP TECHNOLOGY TRENDS</h3>
    <p class="dashboard__top-trends-section-items p4">
      <span
        *ngFor="let item of techTrending; let i = index"
        class="dashboard__top-trends-section-item"
      >
        <a href="{{ item.link }}" target="_blank">{{ item.title }}</a>
        <span *ngIf="i !== techTrending.length - 1">| </span>
      </span>
    </p>
  </div>
  <div class="dashboard__top-trends-separator"></div>
  <div class="dashboard__top-trends-section">
    <h3 class="dashboard__top-trends-section-title">MOST HIRED TECHS 2024</h3>
    <p class="dashboard__top-trends-section-items p4">
      <span
        *ngFor="let item of hireTechs; let i = index"
        class="dashboard__top-trends-section-item"
      >
        <a href="{{ item.link }}" target="_blank">{{ item.title }}</a>
        <span *ngIf="i !== hireTechs.length - 1">| </span>
      </span>
    </p>
  </div>
  <div class="dashboard__top-trends-separator"></div>
  <div class="dashboard__top-trends-section">
    <h3 class="dashboard__top-trends-section-title">TOP ARTICLES</h3>
    <p class="dashboard__top-trends-section-items p4">
      <span
        *ngFor="let item of topArticles; let i = index"
        class="dashboard__top-trends-section-item"
      >
        <a href="{{ item.link }}" target="_blank">{{ item.title }}</a>
        <span *ngIf="i !== topArticles.length - 1">| </span>
      </span>
    </p>
  </div>
</div>
