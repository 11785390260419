import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideNavService {

  sidenavOpen;
  sidenavOpenBS = new BehaviorSubject<boolean>(false);
  
  constructor() { 
    this.sidenavOpen;
    this.sidenavOpenBS.next(this.sidenavOpen);
  }

  setSidenavOpen(val) {
    this.sidenavOpen = val;
    this.sidenavOpenBS.next(this.sidenavOpen);
  }
}
