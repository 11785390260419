import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { WindowService } from '@app/services/window.service';
import { LoaderComponent } from '@app/shared/component/loader/loader.component';
import { TokenService } from '@app/shared/service/token.service';
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  windowRef: any;

  user: any;
  afterCode: boolean = false;
  completeDetails: boolean = false;
  apiLoader: boolean = false;
  dialogData: any;
  phoneFromControl = new FormControl('', [
    Validators.required,
    Validators.minLength(10),
    Validators.maxLength(10),
  ]);
  codeFormControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(6),
  ]);
  nameFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern("^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$"),
  ]);
  emailIdControl = new FormControl('', [Validators.required, Validators.email]);
  secs = 30;
  otpTimer;

  constructor(
    private http: HttpClient,
    private tokenSerice: TokenService,
    private router: Router,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private win: WindowService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    this.dialogData = data;
  }

  ngOnInit(): void {
    this.windowRef = this.win.windowRef;
    const auth = getAuth();
    this.windowRef.recaptchaVerifier = new RecaptchaVerifier(
      'login-recaptcha-container',
      {
        size: 'invisible',
        callback: (response) => {},
        'expired-callback': () => {},
      },
      auth
    );
    this.windowRef.recaptchaVerifier.render();
  }

  OnCreateAccount() {
    this.router.navigate(['auth/register']);
  }

  stopOtpTimer() {
    clearInterval(this.otpTimer);
    this.secs = 30;
    this.afterCode = false;
  }

  async OnSubmit() {
    if (this.phoneFromControl.valid) {
      this.otpTimer = setInterval(() => {
        this.secs--;
        if (this.secs === 0) {
          this.stopOtpTimer();
        }
      }, 1000);

      this.apiLoader = true;
      const phoneNumber = '+91' + this.phoneFromControl.value;
      const appVerifier = this.windowRef.recaptchaVerifier;

      const auth = getAuth();
      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          this.windowRef.confirmationResult = confirmationResult;
          this.windowRef.recaptchaVerifier;
          this.afterCode = true;
          this.apiLoader = false;
        })
        .catch((error) => {
          this.apiLoader = false;
          this.showError('Please try again later');
        });
    } else {
      this.apiLoader = false;
    }
  }

  verify() {
    if (this.codeFormControl.valid) {
      this.apiLoader = true;
      this.windowRef.confirmationResult
        .confirm(this.codeFormControl.value)
        .then((result) => {
          this.user = result.user;
          this.apiLoader = false;
          if (this.user.displayName == null && this.user.email == null) {
            this.completeDetails = true;
          } else {
            localStorage.setItem('tiepleUser', JSON.stringify(this.user));
            let routeState = localStorage.getItem('ROUTE_STATE');
            if (routeState && routeState == 'BOOK_COUNSELING') {
              localStorage.removeItem('ROUTE_STATE');
              this.tokenSerice.setToken(this.user.stsTokenManager.accessToken);
              this.tokenSerice.getUser();
              this.dialog
                .getDialogById('LOGIN_DIALOG')
                ?.close({ data: this.dialogData });
            } else if (routeState && routeState == 'mentors-home') {
              localStorage.removeItem('ROUTE_STATE');
              this.tokenSerice.setToken(this.user.stsTokenManager.accessToken);
              this.tokenSerice.getUser();
              this.dialog
                .getDialogById('LOGIN_DIALOG')
                ?.close({ data: this.dialogData });
            } else {
              this.router.navigate(['']).then(() => {
                window.location.reload();
              });
            }
          }
        })
        .catch((error) => {
          this.apiLoader = false;
          this.showError('Kindly renter the verification code');
        });
    } else {
      this.apiLoader = false;
    }
  }

  update() {
    if (this.nameFormControl.valid && this.emailIdControl.valid) {
      if (this.user.displayName == null) {
        this.apiLoader = true;
        this.user.displayName = this.nameFormControl.value;
        this.user.email = this.emailIdControl.value;
        console.log(this.user);
        this.http
          .post(environment.userWelcomeEmailTrigger, {
            displayName: this.nameFormControl.value,
            email: this.emailIdControl.value,
            uid: this.user.uid,
          })
          .subscribe((resp) => {
            console.log(resp);
            if (resp['status'] == 'success') {
              //this.user['displayName'] = this.nameFormControl.value;
              localStorage.setItem('tiepleUser', JSON.stringify(this.user));
              this.apiLoader = false;
              let routeState = localStorage.getItem('ROUTE_STATE');
              if (routeState && routeState == 'BOOK_COUNSELING') {
                localStorage.removeItem('ROUTE_STATE');
                this.tokenSerice.setToken(
                  this.user.stsTokenManager.accessToken
                );
                this.tokenSerice.getUser();
                this.dialog
                  .getDialogById('LOGIN_DIALOG')
                  ?.close({ data: this.dialogData });
              } else {
                this.router.navigate(['']).then(() => {
                  window.location.reload();
                });
              }
            } else {
              this.apiLoader = false;
              this.showError(
                'Error in saving the user details. Please try again'
              );
            }
          });
        // updateProfile(this.user, { displayName: this.nameFormControl.value }).then(res => {
        //   console.log(res);
        //   updateEmail(this.user, this.emailIdControl.value).then(res => {
        //     localStorage.setItem('tiepleUser', JSON.stringify(this.user));
        //     this.apiLoader = false;
        //     let routeState = localStorage.getItem('ROUTE_STATE');
        //     if (routeState && routeState == 'BOOK_COUNSELING') {
        //       localStorage.removeItem('ROUTE_STATE');
        //       this.tokenSerice.setToken(this.user.stsTokenManager.accessToken);
        //       this.tokenSerice.getUser();
        //       this.dialog.getDialogById('LOGIN_DIALOG')?.close({ data: this.dialogData });
        //     }
        //     else {
        //       this.router.navigate(['']).then(() => {
        //         window.location.reload();
        //       });
        //     }
        //   })

        // }).catch(err => {
        //   this.apiLoader = false;
        //   console.log(err);
        //   this.showError("Please try again later");
        // })
      }
    } else {
      this.apiLoader = false;
    }
  }

  showLoader() {
    let dialogRef: MatDialogRef<LoaderComponent> = this.dialog.open(
      LoaderComponent,
      {
        panelClass: 'transparent',
        disableClose: true,
      }
    );
    return dialogRef;
  }

  showError(msg: string) {
    this.snackbar.open(msg, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
