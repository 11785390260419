<div class="selection-process-outer" id="selection-process-section">
  <div class="selection-process-container">
    <h5 class="selection-process-title lg">Century League Selection Process</h5>
    <p class="selection-process-subtitle p3">
      {{subtitleContent}}
    </p>

    <div class="selection-process-journey">
      <div class="journey-step-wrap active">
        <div class="journey-step-card">
          <h4>Apply</h4>
          <hr />
          <ul>
            <li>
              <h6>Apply for Logical Evaluation</h6>
            </li>
            <li>
              <h6>First step to join</h6>
            </li>
          </ul>
          <vnxt-button class="topic-action" title="Application Form"
            routerLink="../selection-application"></vnxt-button>
        </div>
        <div class="journey-step-connector">
          <hr class="journey-step-line hidden">
          <span class="journey-step-title">FOR ALL</span>
          <span class="journey-step-number">1</span>
        </div>
      </div>
      <div class="journey-step-wrap active">
        <div class="journey-step-card">
          <h4>Logical Evaluation</h4>
          <hr />
          <h6>Participants will receive a real-life problem scenario and are required to articulate their solution
            approach through one of the following mediums:</h6>
          <ul>
            <li class="custom-icon">
              <h6>a VIDEO</h6>
              <p>in English, Hindi or in any local language</p>
            </li>
            <li class="custom-icon">
              <h6>a BLOG or ARTICLE</h6>
              <p>in English only</p>
            </li>
          </ul>
          <p class="p5">
            A dedicated link will be provided for the submission of either a video or blog to your registered email ID
          </p>
          <p class="journey-step-footnote">Take the evaluation from anywhere within declared date range</p>
        </div>
        <div class="journey-step-connector">
          <hr class="journey-step-line">
          <span class="journey-step-title">FOR THOSE WHO BELIEVE IN DOING</span>
          <span class="journey-step-number">2</span>
        </div>
      </div>
      <div class="journey-step-wrap active">
        <div class="journey-step-card">
          <h4>Personal Discussion</h4>
          <hr />
          <p class="p5">
            This entails a one-on-one discussion conducted either in person or virtually through a video call
          </p>
          <p class="p5">
            Purpose of this discussion is to understand the aspiration of the candidate and know better
          </p>
          <p class="p5">
            Professional experts will facilitate this discussion, and the schedule will be communicated to registered
            students via email
          </p>
          <div class="journey-step-connector">
            <hr class="journey-step-line">
            <span class="journey-step-title">IT'S BETTER TO KNOW EACH OTHER</span>
            <span class="journey-step-number">3</span>
          </div>
        </div>
      </div>
    </div>
    <p class="p3 selection-results-info">
      <img src="../../../../../assets/icons/announcement.png" />
      <span>RESULT WILL BE DECLARED WITHIN 24 HOURS AFTER PERSONAL DISCUSSION</span>
    </p>
    <p class="p5">Details regarding formalities will be communicated to the registered email</p>
  </div>