import { Injectable } from '@angular/core';
import { IContentDoc } from '@app/model/Content';
import { Observable } from 'rxjs';
import {
  Firestore,
  collection,
  collectionData,
  getDoc,
  query,
  where,
} from '@angular/fire/firestore';
import { eColName } from '@app/model/collections';
import { TeachingAssistant } from '@app/model/TeachingAssistant';
import { IJourney } from '@app/model/journey';
import { addDoc } from 'firebase/firestore';
import { MentorCategory } from '@app/model/mentor-category';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(private firestore: Firestore) {}

  /**Get all Content*/
  public getContent(): Observable<IContentDoc[]> {
    const contentRef = collection(this.firestore, eColName.CONTENT);
    return collectionData(contentRef, { idField: 'id' }) as Observable<
      IContentDoc[]
    >;
  }

  /** Get all Teaching Assistants */
  public getTeachingAssistants(): Observable<TeachingAssistant[]> {
    const contentRef = collection(this.firestore, eColName.TEACHINGASSISTANT);
    return collectionData(contentRef, { idField: 'id' }) as Observable<
      TeachingAssistant[]
    >;
  }

  /**Get Start Your Journey Cards */
  public getJourneyCards(): Observable<IJourney[]> {
    const contentRef = collection(this.firestore, eColName.JOURNEY);
    return collectionData(contentRef, { idField: 'id' }) as Observable<
      IJourney[]
    >;
  }

  /**Get Start Your Journey Cards */
  public getMentorCategories(): Observable<MentorCategory[]> {
    const contentRef = collection(this.firestore, eColName.MENTOR_CATEGORIES);
    return collectionData(contentRef, { idField: 'generatedId' }) as Observable<
      MentorCategory[]
    >;
  }
  public bookMentorSlot(mentorSlot: any) {
    console.log(mentorSlot);
    const slots = collection(this.firestore, eColName.BOOKED_MENTOR_SLOTS);
    addDoc(slots, mentorSlot);
  }
}
