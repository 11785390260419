import { Component, OnInit } from '@angular/core';
import { ContentService } from '@app/services/content.service';

@Component({
  selector: 'app-top-trends',
  templateUrl: './top-trends.component.html',
  styleUrls: ['./top-trends.component.scss'],
})
export class TopTrendsComponent implements OnInit {
  // techTrending: { title: string; url: string }[] = [
  //   {
  //     title: 'Simple Augmented Reality Android App ',
  //     url: 'https://www.geeksforgeeks.org/how-to-build-a-simple-augmented-reality-android-app/',
  //   },
  //   {
  //     title: ' Artificial Intelligence - How to Get Started ',
  //     url: 'https://www.hackerearth.com/blog/developers/artificial-intelligence-101-how-to-get-started/',
  //   },
  //   {
  //     title: ' IOT Home Automation ',
  //     url: 'https://nevonprojects.com/iot-home-automation-project/',
  //   },
  // ];
  techTrending: any;
  hireTechs: any;
  topArticles: any;
  // hireTechs: { title: string; url: string }[] = [
  //   {
  //     title: 'Top 20 Programming Languages in 2023 ',
  //     url: 'https://www.simplilearn.com/best-programming-languages-start-learning-today-article',
  //   },
  //   {
  //     title: ' Cloud Technologies ',
  //     url: 'https://www.simplilearn.com/cloud-technologies-article',
  //   },
  //   {
  //     title: ' Build your First Mobile App ',
  //     url: 'https://www.entrepreneur.com/science-technology/a-step-by-step-guide-to-building-your-first-mobile-app/231145',
  //   },
  // ];

  // topArticles: { title: string; url: string }[] = [
  //   {
  //     title: 'Quest for the Best: Algorithm’s Efficiency Analysis',
  //     url: '/blogs/algo-efficiency',
  //   },
  //   {
  //     title: 'Chandrayaan 3 Touchdown Spot on Lunar Surface ',
  //     url: 'https://www.gadgets360.com/chandrayaan/news/chandrayaan-3-touchdown-spot-lunar-surface-named-shiva-shakti-point-prime-minister-modi-4331251',
  //   },
  //   {
  //     title: ' Swiggy IPO Plans ',
  //     url: 'https://www.gadgets360.com/apps/news/swiggy-initiates-talks-ipo-plans-eyes-stock-listing-2024-sources-4330631',
  //   },
  //   {
  //     title: ' Zepto Raises $200 Million ',
  //     url: 'https://www.gadgets360.com/apps/news/zepto-first-indian-unicorn-nearly-a-year-usd-200-million-funds-raised-4330487',
  //   },
  //   {
  //     title: ' What is ChatGPT? ',
  //     url: 'https://www.techtarget.com/whatis/definition/ChatGPT',
  //   },
  // ];

  constructor(private contentService: ContentService) {}

  ngOnInit(): void {
    this.contentService.getContent().subscribe((contentData) => {
      let contentSection = contentData.filter(
        (element) => element.section == 'TRENDING_LINKS'
      );
      contentSection.forEach((content) => {
        this.techTrending = content.sectionContent?.filter(
          (sc) => sc.contentType == 'TOP_TRENDING'
        );
        this.hireTechs = content.sectionContent?.filter(
          (sc) => sc.contentType == 'MOST_HIRED'
        );
        this.topArticles = content.sectionContent?.filter(
          (sc) => sc.contentType == 'TOP_ARTICLES'
        );
      });
    });
  }
}
