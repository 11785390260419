<div class="login-dialog-container">
  <div class="asset-section">
    <video width="450" height="560" autoplay loop>
      <source
        src="../../../../../assets/videos/tieple_journey.mp4"
        type="video/mp4"
      />
      Your browser does not support HTML video.
    </video>
  </div>
  <div class="content-section">
    <h3 mat-dialog-title>Connect with Tieple</h3>
    <p class="login-subtitle">your journey begins now.</p>
    <!-- <p class="login-subtitle">
      If you are a new user ,get started with entering your mobile number.
    </p> -->

    <div mat-dialog-content>
      <div class="login-layout">
        <div id="login-recaptcha-container" class="justify-center flex"></div>
        <form
          (ngSubmit)="OnSubmit()"
          class="login-form"
          *ngIf="!completeDetails"
        >
          <mat-form-field appearance="outline">
            <mat-label>Telephone</mat-label>
            <span matPrefix>+91 &nbsp;</span>
            <input
              type="tel"
              matInput
              placeholder="9890 122 112"
              [formControl]="phoneFromControl"
            />
          </mat-form-field>

          <div *ngIf="afterCode">
            <mat-form-field appearance="outline">
              <mat-label>Verification Code</mat-label>
              <input
                type="text"
                matInput
                placeholder="XXXXXX"
                [formControl]="codeFormControl"
              />
            </mat-form-field>
          </div>
          <div>
            <div *ngIf="!afterCode">
              <button
                analyticsTag="Login"
                color="primary"
                mat-button
                mat-raised-button
                [disabled]="apiLoader"
                type="submit"
              >
                <mat-spinner [diameter]="30" *ngIf="apiLoader"></mat-spinner>
                Generate Code
              </button>
            </div>
            <div *ngIf="afterCode">
              <button
                analyticsTag="Login"
                color="primary"
                mat-button
                mat-raised-button
                [disabled]="apiLoader"
                type="button"
                (click)="verify()"
              >
                <mat-spinner [diameter]="30" *ngIf="apiLoader"></mat-spinner>
                Verify
              </button>
              <span class="code-resend-msg">Resend in {{ secs }} secs</span>
            </div>
          </div>
        </form>

        <form *ngIf="completeDetails">
          <mat-form-field appearance="outline">
            <mat-label>Full name</mat-label>
            <input
              type="text"
              matInput
              placeholder="Virat Kohli"
              [formControl]="nameFormControl"
              required
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Email Id</mat-label>
            <input
              type="email"
              matInput
              placeholder="Virat.Kohli@gmail.com"
              [formControl]="emailIdControl"
              required
            />
          </mat-form-field>
          <div>
            <button
              analyticsTag="Login"
              color="primary"
              mat-button
              mat-raised-button
              [disabled]="apiLoader"
              type="button"
              (click)="update()"
            >
              <mat-spinner [diameter]="30" *ngIf="apiLoader"></mat-spinner>
              <span>Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>Close</button>
    </div>
  </div>
</div>
