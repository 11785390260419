export const socialHandles: { key: number, title: string, link: string, imagePath: string }[] = [
  {
    key: 1,
    title: 'Facebook',
    link: 'https://www.facebook.com/tieple.learning',
    imagePath: "../../../../assets/icons/FacebookIcon.png"
  },
  {
    key: 2,
    title: 'Twitter',
    link: 'https://twitter.com/TiepleConnect',
    imagePath: "../../../../assets/icons/TwitterIcon.png"
  },
  // {
  //   key: 3,
  //   title: 'Youtube',
  //   link: 'https://www.youtube.com',
  //   imagePath: "../../../../assets/icons/YoutubeIcon.png"
  // },
  {
    key: 4,
    title: 'LinkedIn',
    link: 'https://www.linkedin.com/company/tieple/about/?viewAsMember=true',
    imagePath: "../../../../assets/icons/LinkedInIcon.png"
  },
  {
    key: 5,
    title: 'Instagram',
    link: 'https://www.instagram.com/tieple.learning/',
    imagePath: "../../../../assets/icons/ChatIcon.png"
  }
];
