import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Firestore,
  collection,
  collectionData,
  getDoc,
  query,
  where,
} from '@angular/fire/firestore';
import { eColName } from '@app/model/collections';
import { addDoc, orderBy } from 'firebase/firestore';
import { News } from '@app/model/news';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private firestore: Firestore) {}

  /**Get all Content*/
  public getNews(): Observable<News[]> {
    const contentRef = collection(this.firestore, eColName.NEWS);
    const refq = query(contentRef, orderBy('posteOn', 'desc'));
    return collectionData(refq, { idField: 'id' }) as Observable<News[]>;
  }
}
