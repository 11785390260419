import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ContentLayoutComponent } from '@layout/public/content-layout/content-layout.component';
import { NavComponent } from '@layout/public/nav/nav.component';
import { FooterComponent } from '@layout/public/footer/footer.component';
import { SharedModule } from '@shared/shared.module';
import {
  VNxtAuthService,
  VNxtCanActivateChildGuard,
  VNxtCanActivateGuard,
  VNxtCanLoadGuard,
  VNxtRouteService,
} from '@vnxt/angular-auth';
import { PerformanceMonitoringService } from '@angular/fire/compat/performance';
import {
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/compat/analytics';
import { environment } from '../../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from '@app/app.component';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { StudentCodeDialogComponent } from './modules/home/components/student-code-dialog/student-code-dialog.component';
import { NewsPageComponent } from './modules/news-page/page/news-page/news-page.component';
@NgModule({
  declarations: [
    AppComponent,
    ContentLayoutComponent,
    NavComponent,
    FooterComponent,
    StudentCodeDialogComponent,
    NewsPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.isCloudDeployment,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    PerformanceMonitoringService,
    VNxtRouteService,
    VNxtAuthService,
    VNxtCanLoadGuard,
    VNxtCanActivateGuard,
    VNxtCanActivateChildGuard,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
