<div class="news-container">
  <div class="news-title-container">
    <mat-toolbar>
      <div class="news-title">
        <h2>QuickEye</h2>
        <h6>Top Tech News</h6>
      </div>
    </mat-toolbar>
  </div>
  <div class="news-content">
    <div
      [ngClass]="
        drawerOpen ? 'news-left-navigation open' : 'news-left-navigation'
      "
    >
      <span class="news-left-nav-expandor" (click)="toggleDrawer()">
        <mat-icon>chevron_right</mat-icon>
      </span>
      <ng-container *ngFor="let filter of newsFilter">
        <a
          [ngClass]="
            filter.category === activeCategory
              ? 'news-filter-links active'
              : 'news-filter-links'
          "
          (click)="gotoNewsSection(filter)"
        >
          {{ filter.linkText }}
        </a>
      </ng-container>
    </div>
    <div class="news-list-container">
      <ng-container *ngFor="let news of currentNewsList">
        <mat-card class="news-card" *ngIf="news.display" [id]="news.id">
          <div class="img-wrapper">
            <img [src]="news.thumbnail" />
          </div>
          <div class="content-wrapper">
            <mat-card-title
              ><h2>{{ news.title }}</h2></mat-card-title
            >
            <mat-card-subtitle
              >Feed by {{ news.author }}, posted on
              {{ news.posteOn }}</mat-card-subtitle
            >
            <mat-card-content>
              <p>{{ news.content }}</p>
            </mat-card-content>
            <mat-card-actions>
              <div class="main-actions-wrapper">
                <button
                  mat-button
                  color="warn"
                  (click)="navigateToSource(news?.link)"
                >
                  {{ news.linkText ?? "Source" }}
                </button>
                <button
                  mat-button
                  color="primary"
                  *ngIf="news.apply"
                  (click)="navigateToSource(news?.applyLink)"
                >
                  Apply Now
                </button>
              </div>
              <button
                mat-icon-button
                color="primary"
                aria-label="Share icon button"
                (click)="shareFeed(news)"
              >
                <mat-icon>share</mat-icon>
              </button>
            </mat-card-actions>
          </div>
        </mat-card>
      </ng-container>
    </div>
  </div>
</div>
