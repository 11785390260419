import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  jwtToken: string = "";
  decodedToken: { [key: string]: string; } | undefined;

  constructor() { 
    let token=localStorage.getItem("tiepleUser");
    if(token){
      this.setToken(JSON.parse(token).stsTokenManager.accessToken);
    }
  }

  setToken(token: string) {
    if (token) {
      this.jwtToken = token;
    }
  }

  decodeToken() {
    if (this.jwtToken) {
      this.decodedToken = jwt_decode(this.jwtToken);
    }
  }

  getDecodeToken() {
    return jwt_decode(this.jwtToken);
  }

  getUser() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['name'] : null;
  }

  getEmailId() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['email'] : null;
  }
  getMobileNo() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['phone_number'] : null;
  }

  getExpiryTime() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['exp'] : "0";
  }

  isTokenExpired(): boolean {
    const expiryTime = this.getExpiryTime() ? parseInt(this.getExpiryTime()) : null;
    if (expiryTime) {
      return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
    } else {
      return true;
    }
  }
}
