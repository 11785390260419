import { Component, Input, OnInit } from '@angular/core';
import { ContentService } from '@app/services/content.service';

@Component({
  selector: 'app-tieple-mentor-community',
  templateUrl: './tieple-mentor-community.component.html',
  styleUrls: ['./tieple-mentor-community.component.scss'],
})
export class TiepleMentorCommunityComponent implements OnInit {
  teacherDetails: any = [];
  @Input() title: string = 'Tieple Mentors Community';

  constructor(private content: ContentService) {
    this.content.getTeachingAssistants().subscribe((teachers) => {
      this.teacherDetails = teachers.filter((t) => t.status == 'ACTIVE');
    });
  }

  ngOnInit(): void {}
}
