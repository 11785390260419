<div class="app-footer__border"></div>
<div class="app-footer__section">
  <div class="app-footer__section-wrap">
    <div class="app-footer__section-tieple">
      <div class="app-footer__section-tieple-logo">
        <img
          alt="logo"
          class="app-footer__section-tieple-logo-img"
          src="../../../../assets/images/tieple_logo.png"
        />
        <h4 class="app-footer__section-tieple-logo-name secondary">tieple</h4>
      </div>
      <p class="app-footer__section-tieple-description p5">
        Tieple - a product of VariableNxt Private Limited, is an R&D
        organization to enhance the quality talent powerhouse in the IT industry
        by providing relevant courses and projects to the students in
        collaboration with IT companies.Tieple creates highly skilled, cultured,
        collaborative and customized IT professionals enabling immediate
        productivity after joining the company. We provide student's data
        insight to registered companies to fulfill their resource requirements.
      </p>
      <div class="app-footer__section-tieple-links">
        <div
          *ngFor="let item of stayConnected"
          class="app-footer__section-tieple-links-option"
        >
          <a [href]="item.link" target="_blank">
            <img alt="logo" [src]="item.imagePath" />
          </a>
        </div>
      </div>
    </div>

    <div class="app-footer__section-list">
      <h4 class="app-footer__section-list-header">Main Menu</h4>
      <p
        *ngFor="let item of tiepleLinks1"
        class="app-footer__section-list-option p5"
      >
        <a
          class="app-footer__section-list-option-link"
          *ngIf="!item.event"
          routerLink="{{ item.url }}"
          target="_blank"
          >{{ item.text }}</a
        >
        <a
          class="app-footer__section-list-option-link"
          *ngIf="item.event"
          (click)="openLoginDialog()"
          target="_blank"
          >{{ item.text }}</a
        >
      </p>
    </div>

    <div class="app-footer__section-list">
      <h4 class="app-footer__section-list-header">Support</h4>
      <p
        *ngFor="let item of tiepleLinks2"
        class="app-footer__section-list-option p5"
      >
        <a
          class="app-footer__section-list-option-link"
          routerLink="{{ item.url }}"
          >{{ item.text }}</a
        >
      </p>
    </div>

    <div class="app-footer__section-contact">
      <h4 class="app-footer__section-contact-header">Contact Us</h4>
      <!-- <div class="app-footer__section-contact-option">
                <div class="app-footer__section-contact-icon">
                    <img alt="logo" src="../../../../assets/icons/PhoneIcon.png" />
                </div>
                <p class="app-footer__section-contact-label p4">7293 020304</p>
            </div> -->
      <div class="app-footer__section-contact-option">
        <div class="app-footer__section-contact-icon">
          <img alt="logo" src="../../../../assets/icons/MailIcon.png" />
        </div>
        <p class="app-footer__section-contact-label p4">connect@tieple.com</p>
      </div>
    </div>
  </div>
</div>
