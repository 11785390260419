import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentService } from '@app/services/content.service';

@Component({
  selector: 'app-experts-say',
  templateUrl: './experts-say.component.html',
  styleUrls: ['./experts-say.component.scss']
})
export class ExpertsSayComponent implements OnInit {

  expertSaysContent: any = []
  //videoURL:string[] = ["https://www.youtube.com/embed/NmA6QmqFTRg","https://www.youtube.com/embed/jmQWOPDqxWA"];
  // playerVars = {
  //   cc_lang_pref: 'en'
  // };
  // version = '...';
  // private player: any;
  videoContent: any = []
  @Input() title: string = "What experts say";

  constructor(private _sanitizer: DomSanitizer, private content: ContentService) {
    this.content.getContent().subscribe(content => {
      this.expertSaysContent = content.filter(c => c.section === "EXPERTS_SAY")[0];
      if (this.expertSaysContent.sectionContent) {
        this.videoContent = [];
        this.expertSaysContent.sectionContent.forEach((item: { contentUrl: string; }) => {
          let safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(item.contentUrl.replace("watch?v=", "embed/"));
          this.videoContent.push(safeURL)
        })
      }
    })
  }

  ngOnInit(): void {
  }

  // Youtube methods
  //  savePlayer(player: any) {
  //   this.player = player;
  // }


}
