import { Injectable } from '@angular/core';
import { ICourse } from '@app/model/Course';
import { Observable } from 'rxjs';
import {
  Firestore, collection, collectionData, getDoc, query, where
} from '@angular/fire/firestore';
import { eColName } from '@app/model/collections';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  public courses: ICourse[] = [];

  constructor(private firestore: Firestore) {
    if(this.courses.length==0){
      this.getTopCourses().subscribe(coursesList => {
        this.courses = coursesList;
        console.log('Courses Service counstructor------>',this.courses);
      })
    }

  }

  /**Get all Active courses */
  public getTopCourses(): Observable<ICourse[]> {
    const coursesRef = collection(this.firestore, eColName.COURSES);
    return collectionData(coursesRef, { idField: "id" }) as Observable<ICourse[]>;
  }

}
