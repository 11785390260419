<h4 mat-dialog-title>
  <mat-icon style="color: green">check_circle_outline</mat-icon>Student Code
</h4>
<div mat-dialog-content>
  <div>
    <mat-label>Student Code &nbsp;</mat-label>
    <input matInput />
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Proceed</button>
  <button mat-button mat-dialog-close>Close</button>
</div>
