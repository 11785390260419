import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { sidebarMenuItems } from '@app/constants/sidebarMenuItems';
import { LoginComponent } from '@app/modules/auth/page/login/login.component';
import { CourseService } from '@app/services/course.service';
import { SideNavService } from '@app/services/side-nav.service';
import { VNxtAuthService } from '@vnxt/angular-auth';
import { TokenService } from '../../../shared/service/token.service';
import { StudentCodeDialogComponent } from '@app/modules/home/components/student-code-dialog/student-code-dialog.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  displayName: string | null = 'Guest';
  email: string | null = 'noemail@tieple.com';
  userLoggedIn: boolean = false;
  menuItems: { key: number; title: string; link: string; imagePath: string }[] =
    sidebarMenuItems;
  sidebarOpen = false;

  constructor(
    private authService: VNxtAuthService,
    public tokenService: TokenService,
    private router: Router,
    public courseService: CourseService,
    private dialog: MatDialog,
    private sidenavService: SideNavService
  ) {}

  ngOnInit() {
    if (!this.tokenService.isTokenExpired()) {
      this.displayName = this.tokenService.getUser();
      this.email = this.tokenService.getEmailId();
    }
  }

  menuAction() {
    this.sidenavService.setSidenavOpen(!this.sidenavService.sidenavOpen);
  }

  logoutUser() {
    this.authService.signOut();
    localStorage.removeItem('tiepleUser');
    this.userLoggedIn = false;
    this.router.navigate(['']).then(() => {
      window.location.reload();
    });
  }

  gotoCompanyPortal() {
    window.location.href = 'https://dev.company.tieple.com';
  }

  openLoginDialog() {
    this.dialog.open(LoginComponent, {
      maxHeight: '560px',
      maxWidth: '800px',
      height: '100%',
      width: '100%',
      panelClass: 'login-modal',
    });
  }

  showStudentDialog() {
    this.dialog.open(StudentCodeDialogComponent, {
      maxHeight: '200px',
      maxWidth: '400px',
      id: 'STUDENT_CODE_SUCCESS_DIALOG',
    });
  }

  ngAfterContentChecked() {
    this.sidebarOpen = this.sidenavService.sidenavOpen;
  }

  loadApplicationForm() {
    if (!this.tokenService.isTokenExpired()) {
      this.router.navigate(['selection-application']);
    } else {
      this.openLoginDialog();
    }
  }
}
