import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatToolbar } from '@angular/material/toolbar';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { News } from '@app/model/news';
import { NewsService } from '@app/services/news.service';
import * as _ from 'underscore';
import * as moment from 'moment';

@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.scss'],
})
export class NewsPageComponent implements OnInit, AfterViewInit {
  newsFilter = [
    { linkText: 'New Tech News', category: 'TECH' },
    { linkText: 'Job Openings', category: 'JOB' },
    { linkText: 'General', category: 'GENERAL' },
  ];
  activeCategory = 'TECH';
  masterNewsList: News[] = [];
  currentNewsList: News[] = [];
  navigatorObj: any = window.navigator;
  drawerOpen = false;
  private fragment: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private newsService: NewsService,
    private titleService: Title,
    private metaTagService: Meta
  ) {}

  ngOnInit(): void {
    this.newsService.getNews().subscribe((newsData) => {
      //console.log('BEOFRE', newsData);
      newsData = _.sortBy(newsData, function (d) {
        //console.log(moment(d.posteOn, 'DD-MM-YYYY').format('DD/MM/YYYY'));
        return new Date(moment(d.posteOn, 'DD-MM-YYYY').format('YYYY/MM/DD'));
      }).reverse();
      //console.log('AFTER', newsData);
      this.masterNewsList = newsData;

      this.titleService.setTitle(
        'Tieple: Technology R&D school | Solving problem using technology'
      );
      this.metaTagService.addTag({
        name: 'description',
        content:
          'Tieple is a technology R&D school dedicated to grooming students and tech enthusiasts into experts to solve real-life problems using technology. We focus on cultivating highly skilled, adaptable, and collaborative IT professionals through a culture of learning by doing. ',
      });
      //console.log(newsData);
      this.route.params.subscribe((param) => {
        let routeText = param['routeId'];
        if (
          routeText &&
          this.newsFilter.filter((filter) => filter.category == routeText)
            .length > 0
        ) {
          this.activeCategory = routeText;
          this.currentNewsList = this.masterNewsList.filter(
            (news) => news.category === this.activeCategory
          );
          this.route.queryParams.subscribe((qparam) => {
            let id = qparam['id'];
            if (id) {
              let currNews = newsData.filter((news) => news.id == id);
              this.fragment =
                currNews && currNews.length > 0 ? currNews[0].id : undefined;
              console.log(this.fragment);
              this.titleService.setTitle(
                currNews && currNews.length > 0 && currNews[0].title
                  ? 'Tieple: ' + currNews[0].title
                  : 'Tieple: Technology R&D school | Solving problem using technology'
              );
              this.metaTagService.addTag({
                name: 'og:title',
                content:
                  currNews && currNews.length > 0 && currNews[0].title
                    ? 'Tieple: ' + currNews[0].title
                    : 'Tieple: Technology R&D school | Solving problem using technology',
              });
              this.metaTagService.addTag({
                name: 'og:description',
                content:
                  'Tieple is a technology R&D school dedicated to grooming students and tech enthusiasts into experts to solve real-life problems using technology. We focus on cultivating highly skilled, adaptable, and collaborative IT professionals through a culture of learning by doing. ',
              });
              this.metaTagService.addTag({
                name: 'og:image',
                content:
                  currNews && currNews.length > 0 && currNews[0].thumbnail
                    ? currNews[0].thumbnail
                    : '',
              });
              setTimeout(() => {
                if (this.fragment != null) {
                  //console.log('frag:', this.fragment);
                  document.getElementById(this.fragment)
                    ? document.getElementById(this.fragment)?.scrollIntoView()
                    : console.log('not a valid section');
                }
              }, 500);
            }
          });
        }
      });
    });
  }

  ngAfterViewInit(): void {
    console.log('hello from afterinit', this.fragment);
    try {
    } catch (e) {
      console.error(e);
    }
  }

  gotoNewsSection(nav: News) {
    this.drawerOpen = false;
    this.router.navigate(['/news', nav.category]);
    this.currentNewsList = this.masterNewsList.filter(
      (news) => news.category === nav.category
    );
  }

  shareFeed(currentNews: News) {
    if (this.navigatorObj.share) {
      const shareData = {
        title: 'Tieple News',
        text: `${currentNews.title}`,
        url: `${document.location.origin}/news/${currentNews.category}?id=${currentNews.id}`,
      };
      this.navigatorObj
        .share(shareData)
        .then(() => {
          console.log('Shared successfully');
        })
        .catch((error) => console.log('Error while sharing -- ', error));
    }
  }

  navigateToSource(src) {
    window.open(src, '_blank');
  }

  toggleDrawer() {
    this.drawerOpen = !this.drawerOpen;
  }
}
