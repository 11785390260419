import {ApplicationRef, enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from '../environments/environment';
import {enableDebugTools} from "@angular/platform-browser";

if (environment.isCloudDeployment) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
bootstrap()
  .then((module) => {
    if ("serviceWorker" in navigator && environment.isCloudDeployment) {
      navigator.serviceWorker.register("ngsw-worker.js");
    }
    if (!environment.isCloudDeployment) {
      console.log(`App Version: ${environment.appVersion}`);
    }
    if (environment.isDevelopEnv) {
      enableDebugTools(module.injector.get(ApplicationRef).components[0]);
    }
  })
  .catch((err) => {
    if (!environment.isDevelopEnv) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  });
