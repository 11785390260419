import { Component, OnInit } from '@angular/core';
import { VNxtRouteService } from "@vnxt/angular-auth";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SideNavService } from './services/side-nav.service';
import { CourseService } from './services/course.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  currentRoute;
  sidebarOpen = false;
  currentMenu;
  currentSubMenu;

  constructor(
    private routerService: VNxtRouteService,
    private activatedRoute: ActivatedRoute,
    private sidenavService: SideNavService,
    public courseService: CourseService,
    private router: Router
  ) {
    router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        this.currentRoute = ev.url;
      }
    });

  }

  async ngOnInit() {
    await this.routerService.makeBreadcrumTreeRecursive(this.activatedRoute);
  }

  gotoSelectedRoute(route) {
    this.currentMenu = '';
    this.currentSubMenu = '';
    this.sidenavService.setSidenavOpen(!this.sidebarOpen);
    this.router.navigateByUrl(route);
  }

  expandMenu(selectedMenu) {
    if (this.currentMenu === selectedMenu) {
      this.currentMenu = '';
    } else {
      this.currentMenu = selectedMenu;
    }
  }

  expandSubMenu(selectedMenu) {
    event?.stopPropagation();
    if (this.currentSubMenu === selectedMenu) {
      this.currentSubMenu = '';
    } else {
      this.currentSubMenu = selectedMenu;
    }
  }

  ngAfterContentChecked() {
    this.sidebarOpen = this.sidenavService.sidenavOpen;
  }
}
