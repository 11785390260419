import { NgModule } from '@angular/core';
import { IvNxtRoutes, VNxtCanActivateGuard } from '@vnxt/angular-auth';
import { RouterModule } from '@angular/router';
import { ContentLayoutComponent } from '@layout/public/content-layout/content-layout.component';
import { AuthorizeGuardService } from './shared/service/authorize-guard.service';

const routes: IvNxtRoutes = [
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [VNxtCanActivateGuard], // Should be replaced with actual auth guard,
    data: {
      Title: 'Title',
      Breadcrumb: 'Title',
      accessPath: [],
    },
    children: [
      {
        path: 'mentors-home',
        loadChildren: () =>
          import('@modules/mentors-home/mentors-home.module').then(
            (m) => m.MentorsHomeModule
          )
      },
      {
        path: 'user-journey',
        loadChildren: () =>
          import('@modules/user-journey/user-journey.module').then(
            (m) => m.UserJourneyModule
          ),
      },
      {
        path: 'student-dashboard',
        loadChildren: () =>
          import('@modules/student-dashboard/student-dashboard.module').then(
            (m) => m.StudentDashboardModule
          ),
        canActivate: [AuthorizeGuardService],
      },
      {
        path: 'student-profile',
        loadChildren: () =>
          import('@modules/student-profile/student-profile.module').then(
            (m) => m.StudentProfileModule
          ),
        canActivate: [AuthorizeGuardService],
      },
      {
        path: 'course-detail/:courseId',
        loadChildren: () =>
          import('@modules/course-detail/course-detail.module').then(
            (m) => m.CourseDetailModule
          ),
      },
      {
        path: 'booking-counselling',
        loadChildren: () =>
          import('@modules/booking-counceling/booking-counceling.module').then(
            (m) => m.BookingCouncelingModule
          ),
      },
      {
        path: 'students-registration',
        loadChildren: () =>
          import(
            '@modules/students-registration/students-registration.module'
          ).then((m) => m.StudentsRegistrationModule),
      },
      {
        path: 'auth',
        loadChildren: () =>
          import('@modules/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'about',
        loadChildren: () =>
          import('@modules/about/about.module').then((m) => m.AboutModule),
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('@modules/contact/contact.module').then(
            (m) => m.ContactModule
          ),
      },
      {
        path: 'students-portal',
        loadChildren: () =>
          import('@modules/student-portal/student-portal.module').then(
            (m) => m.StudentPortalModule
          ),
      },
      {
        path: 'selection-application',
        loadChildren: () =>
          import(
            '@modules/selection-application/selection-application.module'
          ).then((m) => m.SelectionApplicationModule),
      },
      {
        path: 'selection-process',
        loadChildren: () =>
          import('@modules/selection-process/selection-process.module').then(
            (m) => m.SelectionProcessModule
          ),
      },
      {
        path: 'faqs/:routeId',
        loadChildren: () =>
          import('@modules/faq/faq.module').then((m) => m.FaqModule),
      },
      {
        path: 'security-policy',
        loadChildren: () =>
          import('@modules/security-policy/security-policy.module').then(
            (m) => m.SecurityPolicyModule
          ),
      },
      {
        path: 'privacy-policy',
        loadChildren: () =>
          import(
            '@modules/privacy-policy-commitment/privacy-policy-commitment.module'
          ).then((m) => m.PrivacyPolicyCommitmentModule),
      },
      {
        path: 'cookie-policy',
        loadChildren: () =>
          import('@modules/cookie-policy/cookie-policy-routing.module').then(
            (m) => m.CookiePolicyRoutingModule
          ),
      },
      {
        path: 'privacy-notice',
        loadChildren: () =>
          import('@modules/privacy-notice/privacy-notice-routing.module').then(
            (m) => m.PrivacyNoticeRoutingModule
          ),
      },
      {
        path: 'blogs/:title',
        loadChildren: () =>
          import('@modules/article/article-routing.module').then(
            (m) => m.ArticleRoutingModule
          ),
      },
      {
        path: 'news/:routeId',
        loadChildren: () =>
          import('@modules/news-page/news-page-routing.module').then(
            (m) => m.NewsPageRoutingModule
          ),
      },
      {
        path: 'century-league',
        loadChildren: () =>
          import('@modules/super-league/super-league-routing.module').then(
            (m) => m.SuperLeagueRoutingModule
          )
      },
      //import { StudentsRegistrationComponent } from './components/students-registration/students-registration.component.spec';
      {
        // Fallback when no prior routes is matched
        path: '**',
        loadChildren: () =>
          import('@modules/home/home.module').then((m) => m.HomeModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
